<template>
  <section class="empresa__view">
    <div v-if="loading" class="circle-loader"></div>
    <!-- Main content -->
    <div class="content" v-if="empresa">
      <b-row>
        <b-col sm="12" md="3">
          <!-- Imagem da Empresa -->
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <div class="text-center">
                <img
                  class="profile-user-img img-fluid img-circle"
                  src="@/assets/images/factory_2-128.png"
                  alt="Imagem da Empresa"
                />
              </div>

              <template v-if="empresa.nome_fantasia">
                <h3 class="profile-username text-center">
                  {{ empresa.nome_fantasia }}
                </h3>
                <p v-if="empresa.empresa_base" class="text-muted text-center">
                  {{ empresa.empresa_base.razao_social }}
                </p>
              </template>
              <template v-else-if="empresa.empresa_base">
                <h3 class="profile-username text-center">
                  {{ empresa.empresa_base.razao_social }}
                </h3>
              </template>
              <template v-else>
                <h3 class="profile-username text-center">
                  {{ empresa.cnpj | cnpjFilter }}
                </h3>
              </template>

              <div class="py-4">
                <p class="clearfix">
                  <span class="float-left ">
                    <b><i class="fa fa-users mr-1"></i> Contatos </b>
                  </span>
                  <span class="float-right text-muted">
                    {{ empresa.contatos.length }}
                  </span>
                </p>
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-phone mr-1"></i> Telefones</b>
                  </span>
                  <span class="float-right text-muted">
                    {{ empresa.empresa_telefones.length }}
                  </span>
                </p>
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-envelope mr-1"></i> Emails</b>
                  </span>
                  <span class="float-right text-muted">
                    {{ empresa.empresa_emails.length }}
                  </span>
                </p>
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="fa fa-link mr-1"></i> Domínios</b>
                  </span>
                  <span class="float-right text-muted">
                    {{ empresa.empresa_websites.length }}
                  </span>
                </p>
                <p class="clearfix">
                  <span class="float-left">
                    <b><i class="mdi mdi-tie mr-1"></i> Sócios</b>
                  </span>
                  <span class="float-right text-muted">
                    {{
                      empresa.empresa_base
                        ? empresa.empresa_base.socios.length
                        : 0
                    }}
                  </span>
                </p>
              </div>

              <!-- <a href="#" class="btn btn-primary btn-block"><b>Seguir</b></a> -->

              <strong>
                <i class="fa fa-dollar mr-1"></i> Faturamento
              </strong>

              <p class="text-muted" v-if="empresa.empresa_estatistica">
                R$
                {{ empresa.empresa_estatistica.faturamento_min | numberFilter }}
                a
                {{ empresa.empresa_estatistica.faturamento_max | numberFilter }}
              </p>
              <p class="text-muted" v-else>Indisponível</p>

              <hr />

              <strong>
                <i class="fa fa-money mr-1"></i> Capital Social
              </strong>

              <p class="text-muted">
                <template v-if="empresa.empresa_base">
                  R$ {{ empresa.empresa_base.capital_social | numberFilter }}
                </template>
                <template v-else>Indisponível</template>
              </p>

              <hr />

              <strong>
                <i class="fa fa-info mr-1"></i> Atividade Principal
              </strong>

              <p class="text-muted">
                {{ empresa.cnae.secao }}-{{ empresa.cnae.cnae }} -
                {{ empresa.cnae.denominacao }}
              </p>

              <hr />

              <strong><i class="fa fa-users mr-1"></i> Funcionários</strong>
              <p class="text-muted" v-if="empresa.empresa_estatistica">
                {{
                  empresa.empresa_estatistica.funcionarios_min | numberFilter
                }}
                a
                {{
                  empresa.empresa_estatistica.funcionarios_max | numberFilter
                }}
                funcionários
              </p>
              <p class="text-muted" v-else>Indisponível</p>

              <hr />

              <strong
                ><i class="fa fa-building mr-1"></i> Porte na Receita</strong
              >
              <p class="text-muted">
                {{
                  empresa.empresa_base
                    ? empresa.empresa_base.empresa_porte.descricao
                    : "Indisponível"
                }}
              </p>

              <hr />

              <strong
                ><i class="fa fa-map-marker mr-1"></i> Endereço</strong
              >
              <p class="text-muted">
                {{ empresa.tipo_logradouro }} {{ empresa.logradouro }},
                {{ empresa.numero }} - {{ empresa.bairro }}<br />
                {{ empresa.municipio.nome }} - {{ empresa.uf }},
                {{ empresa.cep | cepFilter }}
              </p>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </b-col>

        <b-col sm="12" md="9">
          <b-card no-body>
            <b-tabs pills fill card>
              <b-tab title="Contatos" active>
                <b-card-body>
                  <b-table
                    stacked="md"
                    :items="empresa.contatos"
                    :fields="contatosFields"
                  >
                    <template #cell(contatos)="row">
                      <b-button-group>
                        <b-button
                          :id="'telefone-popover-' + row.item.id"
                          variant="outline-primary"
                        >
                          <i class="mdi mdi-phone"></i>
                        </b-button>
                        <b-button
                          :id="'email-popover-' + row.item.id"
                          variant="outline-primary"
                          class="ml-1"
                        >
                          <i class="mdi mdi-email"></i>
                        </b-button>
                      </b-button-group>
                      <b-popover
                        :target="'telefone-popover-' + row.item.id"
                        triggers="hover"
                        placement="top"
                        variant="info"
                      >
                        <template #title>Telefones</template>
                        <b-list-group flush>
                          <b-list-group-item
                            variant="info"
                            v-for="telefone in row.item.contato_telefones"
                            :key="telefone.id"
                          >
                            {{ telefone.telefone }}
                            <b-badge v-if="telefone.valido" variant="success"
                              >Válido</b-badge
                            >
                            <b-badge
                              v-else-if="telefone.valido === 0"
                              variant="danger"
                              >Inválido</b-badge
                            >
                            <b-badge v-else variant="dark"
                              >Validação Pendente</b-badge
                            >
                          </b-list-group-item>
                        </b-list-group>
                      </b-popover>
                      <b-popover
                        :target="'email-popover-' + row.item.id"
                        triggers="hover"
                        placement="top"
                        variant="info"
                      >
                        <template #title>Emails</template>
                        <b-list-group flush>
                          <b-list-group-item
                            variant="info"
                            v-for="email in row.item.contato_emails"
                            :key="email.id"
                          >
                            {{ email.email }}
                            <b-badge v-if="email.valido" variant="success"
                              >Válido</b-badge
                            >
                            <b-badge
                              v-else-if="email.valido === 0"
                              variant="danger"
                              >Inválido</b-badge
                            >
                            <b-badge v-else variant="dark"
                              >Validação Pendente</b-badge
                            >
                          </b-list-group-item>
                        </b-list-group>
                      </b-popover>
                    </template>

                    <template #cell(actions)="row">
                      <b-button
                        variant="outline-primary"
                        class="mr-1"
                        title="Visualizar"
                        :to="{
                          name: 'ContatoView',
                          params: { id: row.item.id },
                        }"
                      >
                        Visualizar
                      </b-button>
                    </template>
                  </b-table>
                </b-card-body>
              </b-tab>
              <b-tab title="Telefones">
                <b-list-group flush>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    v-for="telefone in empresa.empresa_telefones"
                    :key="telefone.id"
                  >
                    {{ telefone.telefone }}
                    <!-- <b-form-rating id="rating-inline" inline value="4" readonly></b-form-rating> -->
                    <b-badge v-if="telefone.valido" variant="success"
                      >Válido</b-badge
                    >
                    <b-badge v-else-if="telefone.valido === 0" variant="danger"
                      >Inválido</b-badge
                    >
                    <b-badge v-else variant="secondary"
                      >Validação Pendente</b-badge
                    >
                  </b-list-group-item>
                </b-list-group>
              </b-tab>
              <b-tab title="Websites">
                <b-list-group flush>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    v-for="website in empresa.empresa_websites"
                    :key="website.id"
                  >
                    <a
                      :href="website.url"
                      target="_blank"
                      v-b-tooltip.hover
                      title="Abrir site em nova aba"
                      >{{ website.url }}</a
                    >
                  </b-list-group-item>
                </b-list-group>
              </b-tab>
              <b-tab title="Emails">
                <b-card-body>
                  <b-table
                    stacked="md"
                  
                    :items="empresa.empresa_emails"
                    :fields="emailFields"
                  >
                    <template #cell(valido)="row"
                      >{{ row.value }}
                      <b-badge v-if="row.value" variant="success"
                        >Válido</b-badge
                      >
                      <b-badge v-else-if="row.value === 0" variant="danger"
                        >Inválido</b-badge
                      >
                      <b-badge v-else variant="secondary"
                        >Validação Pendente</b-badge
                      >
                    </template>
                  </b-table>
                </b-card-body>
              </b-tab>
              <b-tab title="Sócios">
                <b-card-body>
                  <h5>Sócios e Administradores</h5>
                  <b-table
                    stacked="md"
                    :items="
                      empresa.empresa_base ? empresa.empresa_base.socios : []
                    "
                    :fields="sociosFields"
                  >
                    <template #cell(data_entrada)="row">
                      {{ row.value | formatDate }}
                    </template>
                  </b-table>
                </b-card-body>
              </b-tab>
              <b-tab title="Dados da Receita Federal">
                <b-card-body>
                  <b-row>
                    <b-col cols="6">
                      <dl>
                        <dt><i class="fa fa-file mr-1"></i> CNPJ</dt>
                        <dd>{{ empresa.cnpj | cnpjFilter }}</dd>
                        <dt>Razão Social</dt>
                        <dd>
                          {{
                            empresa.empresa_base
                              ? empresa.empresa_base.razao_social
                              : "-"
                          }}
                        </dd>
                        <dt>Nome Fantasia</dt>
                        <dd>{{ empresa.nome_fantasia }}</dd>
                        <dt>Natureza Jurídica</dt>
                        <dd>
                          {{
                            empresa.empresa_base
                              ? empresa.empresa_base.natureza_juridica.codigo +
                                " - " +
                                empresa.empresa_base.natureza_juridica.nome
                              : "-"
                          }}
                        </dd>
                        <dt>Capital Social</dt>
                        <dd>
                          <template v-if="empresa.empresa_base">
                            R$
                            {{
                              empresa.empresa_base.capital_social | numberFilter
                            }}
                          </template>
                          <template v-else>-</template>
                        </dd>
                        <dt>Atividade Principal</dt>
                        <dd>
                          {{ empresa.cnae.secao }}-{{ empresa.cnae.cnae }} -
                          {{ empresa.cnae.denominacao }}
                        </dd>
                      </dl>
                    </b-col>
                    <b-col cols="6">
                      <dl>
                        <dt>Enquadramento de Porte</dt>
                        <dd>
                          {{
                            empresa.empresa_base
                              ? empresa.empresa_base.empresa_porte.descricao
                              : "-"
                          }}
                        </dd>
                        <dt>Situacão</dt>
                        <dd>{{ empresa.empresa_situacao.descricao }}</dd>
                        <dt>Data Situacão</dt>
                        <dd>
                          {{ empresa.data_situacao_cadastral | formatDate }}
                        </dd>
                        <dt>Data Abertura</dt>
                        <dd>{{ empresa.data_abertura | formatDate }}</dd>
                        <dt>Tipo Unidade</dt>
                        <dd>
                          {{ empresa.matriz_filial | matrizFilialFilter }}
                        </dd>
                        <template v-if="empresa.matriz_filial == 1">
                          <dt>Filiais</dt>
                          <dd>
                            {{
                              empresa.empresa_base
                                ? empresa.empresa_base.estabelecimentos
                                : 0
                            }}
                          </dd>
                        </template>
                        <!-- <dt>Optante pelo Simples</dt>
                        <dd>{{ empresa.opcao_simples | simnaoFilter }}</dd>
                        <dt>Optante pelo MEI</dt>
                        <dd>{{ empresa.opcao_mei | simnaoFilter }}</dd> -->
                      </dl>
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col cols="6">
                      <dl>
                        <dt>
                          <i class="fa fa-map-marker mr-1"></i> Endereço
                        </dt>
                        <dd>
                          {{ empresa.tipo_logradouro }}
                          {{ empresa.logradouro }}, {{ empresa.numero }} -
                          {{ empresa.bairro }}
                        </dd>
                        <dt>Cidade</dt>
                        <dd>{{ empresa.municipio.nome }}</dd>
                        <dt>Complemento</dt>
                        <dd>{{ empresa.complemento }}</dd>
                      </dl>
                    </b-col>
                    <b-col cols="6">
                      <dl>
                        <dt>CEP</dt>
                        <dd>{{ empresa.cep | cepFilter }}</dd>
                        <dt>UF</dt>
                        <dd>{{ empresa.uf }}</dd>
                      </dl>
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col cols="6">
                      <dl>
                        <template v-if="empresa.telefone1">
                          <dt>
                            <i class="fa fa-phone mr-1"></i> Telefone 1
                          </dt>
                          <dd>({{ empresa.ddd1 }}) {{ empresa.telefone1 }}</dd>
                        </template>
                        <template v-if="empresa.email">
                          <dt><i class="fas fa-envelope mr-1"></i> Email</dt>
                          <dd>{{ empresa.email }}</dd>
                        </template>
                      </dl>
                    </b-col>
                    <b-col cols="6">
                      <dl>
                        <template v-if="empresa.telefone2">
                          <dt>
                            <i class="fa fa-phone mr-1"></i> Telefone 2
                          </dt>
                          <dd>({{ empresa.ddd2 }}) {{ empresa.telefone2 }}</dd>
                        </template>
                      </dl>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import EmpresaService from "../../services/empresa.service";

export default {
  name: "EmpresaView",
  props: ["id"],
  data() {
    return {
      empresa: null,
      loading: false,
      sociosFields: [
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "socio_qualificacao.descricao",
          label: "Qualificação",
          sortable: true,
        },
        "data_entrada",
      ],
      emailFields: ["nome", "email", { key: "valido", label: "Status" }],
      contatosFields: [
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "departamento.nome",
          sortable: false,
          label: "Depto.",
        },
        {
          key: "nivel_hierarquico.nome",
          sortable: false,
          label: "Nível",
        },
        {
          key: "contatos",
          sortable: false,
          label: "Contatos",
        },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      this.empresa = null;
      this.loading = true;
      EmpresaService.get(this.id)
        .then((response) => {
          this.empresa = response.data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>